<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="6" class="text-center text-sm-left mb-sm-0">
          <h1 class="header-main text-uppercase mb-0">
            เงื่อนไขขนส่งภายในประเทศ
          </h1>
        </b-col>
        <!-- <b-col lg="6" class="text-right d-flex justify-content-end">
          <b-dropdown
            class="ml-2 lang-dd border-0"
            variant="outline-dark"
            :text="'ตัวเลือกเพิ่มเติม'"
            right
          >
            <b-dropdown-item-button
              @click="importProduct"
              :disabled="checkStatus"
            >
              นำเข้าข้อมูล (.xlsx)
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="downloadTemplate">
              ดาวน์โหลดไฟล์ต้นฉบับ
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col> -->
      </CRow>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row class="mt-0">
          <b-col md="6">
            <InputSelect
              class=""
              classLabelName="col-lg-4 my-auto"
              classInputName="col-lg-6"
              title="ขนส่ง"
              name="shipping"
              valueField="shippingTypeId"
              textField="shippingTypeName"
              v-bind:options="shippingLists"
              v-model="form.shippingTypeId"
              @onDataChange="(value) => (form.shippingTypeId = value)"
              isRequired
              :v="$v.form.shippingTypeId"
            />
          </b-col>
          <b-col md="6">
            <InputSelect
              class=""
              classLabelName="col-lg-4 my-auto"
              classInputName="col-lg-6"
              title="ประเภทเงื่อนไข"
              name="shippingConditionId"
              valueField="id"
              textField="name"
              v-bind:options="conditionLists"
              v-model="form.shippingConditionId"
              @onDataChange="(value) => (form.shippingConditionId = value)"
              isRequired
              :v="$v.form.shippingConditionId"
            />
          </b-col>
        </b-row>
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="form.details"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(startRange)="data">
                <InputText
                  placeholder="ระบุน้ำหนักที่ต้องการ"
                  type="number"
                  name="startRange"
                  v-model="data.item.startRange"
                  isRequired
                  class="mt-3"
                  :maxValue="99999"
                  :disabled="data.index == 0"
                  :isValidate="
                    $v.form.details.$each.$iter[data.index].startRange.$error
                  "
                  :v="$v.form.details.$each.$iter[data.index].startRange"
                />
              </template>
              <template v-slot:cell(endRange)="data">
                <InputText
                  placeholder="ระบุน้ำหนักที่ต้องการ"
                  type="number"
                  name="endRange"
                  v-model="data.item.endRange"
                  isRequired
                  class="mt-3"
                  :maxValue="99999"
                  :isValidate="
                    $v.form.details.$each.$iter[data.index].endRange.$error
                  "
                  :v="$v.form.details.$each.$iter[data.index].endRange"
                />
              </template>
              <template v-slot:cell(cost)="data">
                <InputText
                  placeholder="ระบุค่าขนส่งที่ต้องการ"
                  type="number"
                  name="cost"
                  v-model="data.item.cost"
                  isRequired
                  class="mt-3"
                  :isValidate="
                    $v.form.details.$each.$iter[data.index].cost.$error
                  "
                  :v="$v.form.details.$each.$iter[data.index].cost"
                />
              </template>
              <template v-slot:cell(action)="data">
                <div
                  class="text-center"
                  v-if="form.details.length > 1 && data.index != 0"
                >
                  <b-button
                    class="btn btn-danger btn-details-set mr-md-2"
                    @click="deleteData(data.index)"
                    >ลบ</b-button
                  >
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row
          class="mt-0 d-flex justify-content-center my-2"
          v-if="form.shippingConditionId != 4"
        >
          <b-button class="button btn-cancel ml-2" @click="addNewConditon()"
            >เพิ่มเงื่อนไข</b-button
          >
        </b-row>
      </b-container>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row class="px-3">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="form.enable"
                >
                  <span class="ml-2 main-label">
                    {{ form.enable ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
          <b-col class="d-flex justify-content-end align-items-center">
            <router-link :to="'/shipping-setting/thai'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <b-button class="btn-main ml-3" @click="checkForm()"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Modal -->
    <!-- <ModalAlertConfirm
        msg="ยืนยันการลบ ?"
        :text="modalMessage"
        btnConfirm="ลบ"
        colorBtnConfirm="danger"
        btnCancel="ยกเลิก"
        ref="ModalAlertConfirm"
        @confirm="btnDelete"
      /> -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";

import { required, requiredIf } from "vuelidate/lib/validators";

const validateMaxValue = (value) => {
  if (parseInt(value) <= 99999) {
    return false;
  } else {
    return true;
  }
};

const validateMinvalue = (value) => {
  if (parseInt(value) >= 0) {
    return false;
  } else {
    return true;
  }
};

const validateRange = (circuit) => {
  if (parseFloat(circuit.startRange) < parseFloat(circuit.endRange)) {
    return false;
  } else {
    return true;
  }
};

export default {
  name: "SettingDetails",
  components: {
    InputText,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
    InputSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      fields: [
        {
          key: "id",
          label: "",
          class: "w-25px",
        },
        {
          key: "startRange",
          label: "น้ำหนักเริ่มต้น (กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "endRange",
          label: "น้ำหนักสิ้นสุด (กิโลกรัม)",
          class: "w-100px text-nowrap",
        },
        {
          key: "cost",
          label: "ค่าขนส่ง (บาท)",
          class: "w-100px text-nowrap",
        },
        {
          key: "action",
          label: "",
        },
      ],
      isBusy: false,
      conditionLists: [],
      shippingLists: [],
      error: [],
      form: {
        id: parseInt(this.$route.params.id),
        enable: true,
        shippingTypeId: 1,
        shippingConditionId: 1,
        details: [
          {
            startRange: 0.0,
            endRange: "",
            cost: "",
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        shippingTypeId: { required },
        shippingConditionId: { required },
        details: {
          $each: {
            startRange: {
              required: requiredIf(function () {
                return this.form.shippingConditionId != 4;
              }),
              validateMinvalue: function (value) {
                return !validateMinvalue(value);
              },
              validateMaxValue: function (value) {
                return !validateMaxValue(value);
              },
            },
            endRange: {
              required: requiredIf(function () {
                return this.form.shippingConditionId != 4;
              }),
              validateMinvalue: function (value) {
                return !validateMinvalue(value);
              },
              validateMaxValue: function (value) {
                return !validateMaxValue(value);
              },
              validateRange: function (value, circuit) {
                return !validateRange(circuit);
              },
            },
            cost: {
              required,
              validateMinvalue: function (value) {
                return !validateMinvalue(value);
              },
            },
          },
        },
      },
    };
  },
  watch: {
    "form.shippingConditionId": async function (e) {
      this.$v.form.$reset();
      this.isBusy = true;
      if (e == 4) {
        this.form.details = [
          {
            cost: this.form.minimumCost ? this.form.minimumCost : "",
          },
        ];
        this.fields = [
          {
            key: "cost",
            label: "ค่าขนส่ง (บาท)",
            class: "w-50 text-nowrap",
          },
          {
            key: "action",
            class: "w-100px",
            label: "",
          },
        ];
      } else {
        this.form.details = [
          {
            startRange: 0.0,
            endRange: "",
            cost: "",
          },
        ];
        this.fields = [
          {
            key: "id",
            label: "",
            class: "w-25px",
          },
          {
            key: "startRange",
            label: "น้ำหนักเริ่มต้น (กิโลกรัม)",
            class: "w-100px text-nowrap",
          },
          {
            key: "endRange",
            label: "น้ำหนักสิ้นสุด (กิโลกรัม)",
            class: "w-100px text-nowrap",
          },
          {
            key: "cost",
            label: "ค่าขนส่ง (บาท)",
            class: "w-100px text-nowrap",
          },
          {
            key: "action",
            label: "",
          },
        ];
      }
      this.isBusy = false;
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addNewConditon: function () {
      this.form.details.push({
        startRange: "",
        endRange: "",
        cost: "",
      });
    },
    deleteData: function (index) {
      this.form.details.splice(index, 1);
    },
    getList: async function () {
      this.isBusy = true;
      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Shipping/ThaiShipping`,
        null,
        this.$headers,
        this.filter
      );
      if (res.result == 1) {
        this.shippingLists = res.detail;
      }

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/shipping/shippingcondition`,
        null,
        this.$headers,
        this.filter
      );
      if (data.result == 1) {
        this.conditionLists = data.detail;
      }

      if (this.id != 0) {
        let resData = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/Shipping/ThaiShipping/${this.id}`,
          null,
          this.$headers,
          this.filter
        );

        if (resData.result == 1) {
          this.form = resData.detail;
        }
        console.log(this.form);
      }
      this.isBusy = false;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.$nextTick(() => {
          let target = document.querySelector(".text-error");
          if (target) {
            let domRect = document
              .querySelector(".text-error")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 450
            );
          }
        });
        return;
      }

      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Shipping/ThaiShipping/Save`,
        null,
        this.$headers,
        this.form
      );
      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/shipping-setting/thai`,
          });
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .table td {
  vertical-align: baseline !important;
}

.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error,
.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

label {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  width: 100%;
  font-weight: bold;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .dropdown-menu-right {
  width: 200px;
  padding: 5px;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

.category-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  padding: 5px 10px;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
  border: 1px solid #bcbcbc !important;
}

.category-popover {
  position: absolute;
  z-index: 100;
  border: 1px solid #dcdcdc;
  overflow-y: auto;
  right: 0;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12),
    0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}

@media (max-width: 600px) {
  .category-main-box {
    width: 100%;
    margin-top: 10px;
  }

  .category-popover {
    right: 15px;
  }
}
</style>
